import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import GNXServices from "../molecules/GokenNihon/Services";
// import qualityMatrix from "../images/gokenNihon/quality_matrix.png";
import headerBigImg from "../images/gokenNihon/header.jpg";
import headerSmallImg from "../images/gokenNihon/mob_header.jpg";
// import adasImg from "../images/gokenNihon/adas_vnv.png";
import workWithImg from "../images/gokenNihon/work_with_us.png";
import staffingImg from "../images/gokenNihon/staffing.png";
import engServices from "../images/gokenNihon/consult.png";
import engDesign from "../images/gokenNihon/eng_design.png";
import JPLangServices from "../images/gokenNihon/language.png";

// import mobEngConsult from "../images/gokenNihon/mob-eng-consult.png";
// import mobEngDesign from "../images/gokenNihon/mob-eng-design.png";
// import mobStaffing from "../images/gokenNihon/mob-staffing.png";
// import mobLangServices from "../images/gokenNihon/mob-lang-service.png";
import GNXDescription from "../molecules/GokenNihon/Header";
import FundamentalBeliefs from "../molecules/GokenNihon/FundamentalBeliefs";
import { useLanguage } from "../languageContext";
// import footerImg from "../images/gokenNihon/footerGlobeImg.png";

import quality from "../images/gokenNihon/service_quality.png";
import execution from "../images/gokenNihon/service_execution.png";
import value from "../images/gokenNihon/service_value.png";
import EmployeeTestimonial from "../molecules/GokenNihon/EmployeeTestimonial";

// ARROWS
import leftJaArr from "../images/gokenNihon/right-Arrow-ja.png";
import rightJaArr from "../images/gokenNihon/left-Arrow-ja.png";
import Jobs from "../molecules/GokenNihon/Jobs";
import ContactUs from "../molecules/GokenNihon/ContactUs";
// import Map from "../molecules/GokenNihon/Map";
// import WebFooter from "../molecules/Footer/v2/Footer";
// import FooterLocations from "../molecules/Footer/v2/FooterLocations";

// import globeImg from "../images/gokenNihon/footerGlobeImg.png";
import Footer from "../molecules/GokenNihon/Footer";

// mobile services images

import mobEng from "../images/gokenNihon/eng_mobile.png";
import mobConsult from "../images/gokenNihon/consult_mobile.png";
import mobLanguage from "../images/gokenNihon/language_mobile.png";
import mobStaffing from "../images/gokenNihon/staffing_mobile.png";
// import Header from "../molecules/GokenNihon/Header";
import PageHeader from "../molecules/GokenNihon/PageHeader";
// import Header from "../molecules/GokenIndia/Header";

const GokenNihon = () => {
  const currentLang = useLanguage();
  const [pageData, setPageData] = useState({});
  const data = {
    header: {
      en: {
        headingOne: "Goken",
        headingTwo: "NIHON",
        // subHeading: "Yokohama, Japan",
        // para: "Established 2006",
        establish: { smallTitle: "Established In", bigTitle: "2006" },
        location: { smallTitle: "Located In", bigTitle: "Yokohama, Japan" },

        pageHeading: "Our Story...",
        paragraph1: `Goken Nihon has built a culturally diverse corporation along with Goken America and Goken India. Since its establishment in 2006, Goken Nihon has taken up challenging projects in technology development and communication services for the automotive industry. Customer satisfaction lies at the core of our philosophy. This has enabled us to take up difficult tasks and help us earn the trust of our customers.`,
        paragraph2: `In recent years, we have adapted quickly to changing business environment. This has helped stay nimble and continue to move ahead with passion and creativity. Our ability to share resources - management tools and human resources - with United States and India has made Goken a truly global company. Goken Nihon's relentless quest for customer satisfaction is based on three pillars.`,
      },
      ja: {
        headingOne: "Goken",
        headingTwo: "NIHON",
        // subHeading: "横浜、日本",
        // para: "2006年設立",
        pageHeading: "会社案内",
        establish: { smallTitle: "年設立", bigTitle: "2006" },
        location: { smallTitle: "に位置", bigTitle: "横浜、日本" },
        paragraph1:
          "GokenNIHONは、Goken AmericaおよびGoken Indiaとともに、多様性のある企業です。2006年の設立以来、GokenNIHONでは、自動車業界向けの製品開発や翻訳・通訳サービスなど、チャレンジングなプロジェクトに取り組んできました。近年、当社ではビジネス環境の変化に素早く対応しています。これにより、私たちは高いアジリティを保ち、情熱と創造力を持って前進し続けることができるのです。経営理念や人材リソースを米国やインドと共有することで、Gokenは真のグローバル企業となりました。顧客満足度は、当社の企業理念の中核を担っています。",
        paragraph2: `​

GokenNIHONの高い顧客満足度へのあくなき追求は、三つの柱に基づいています。​

​`,
      },
    },

    beliefs: {
      en: {
        // heading: `Our Fundamental Beliefs`,
        title1: "Quality",
        title2: "Value",
        title3: "Execution",
        // paragraph: `In recent years, we have adapted quickly to changing business environment. This has helped stay nimble and continue to move ahead with passion and creativity. Our ability to share resources - management tools and human resources - with United States and India has made Goken a truly global company.
        //             Goken Nihon's relentless quest for customer satisfaction is based on three pillars.`,
        list: [
          {
            title: "Quality",
            paragraph: `We strictly adhere to customer satisfaction through our high quality offerings. We are committed to working hard to deliver the ideal service and output to our customers.`,
            image: quality,
          },
          {
            title: "Value",
            paragraph: `At Goken, we always strike a healthy balance of cost and benefit to our customers. This ensures competitive prices for our customers with a reliable output.`,
            image: value,
          },
          {
            title: "Execution",
            paragraph: `We understand that our customers are doing business on tight schedules. Goken Nihon understands the importance of working closely with customers.`,
            image: execution,
          },
        ],
      },
      ja: {
        // heading: `Our Fundamental Beliefs`,
        title1: "品質",
        title2: "価値",
        title3: "実行",
        // paragraph: `In recent years, we have adapted quickly to changing business environment. This has helped stay nimble and continue to move ahead with passion and creativity. Our ability to share resources - management tools and human resources - with United States and India has made Goken a truly global company.
        //             Goken Nihon's relentless quest for customer satisfaction is based on three pillars.`,
        list: [
          {
            title: "品質",
            paragraph: `当社では、高品質な製品やサービスを提供することで高い顧客満足度を徹底的に追求しています。 私たちは、お客様に理想的なサービスと成果をお届けできるよう、全力を尽くします。`,
            image: quality,
          },
          {
            title: "価値",
            paragraph: `Gokenでは、お客様にとってのコストと利益の健全なバランスを常に保っています。これにより、信頼性の高い成果物と競争力のある価格をお客様に提供することができます。`,
            image: value,
          },
          {
            title: "実行",
            paragraph: `GokenNIHONでは、お客様がタイトなスケジュールで事業を展開し、私たちがお客様と密接に連携することの重要性を十分に理解しています。`,
            image: execution,
          },
        ],
      },
    },
    services: {
      en: {
        heading: "Goken Nihon",
        jaColoredTitle: "Services",
        linkText: "Contact Us",
        link: "/contactus/",
        list: [
          {
            heading: "Engineering Design",
            paragraph: `Goken's experience in engineering design spans the entire cycle right from - Initial Concept to Production Launch. Our core expertise includes designing Body, Chassis, and Interior systems. Engineers at Goken have worked with part designs, sub-system designs, and system designs for numerous leading companies.`,
            // link: "",
            // linkText: "Read more...",
            image: engDesign,
            mobileImage: mobEng,
            mobilePara: `Goken's experience in engineering design spans the entire cycle right from - Initial Concept to Production Launch.`,
          },

          {
            heading: "Engineering Consulting",
            paragraph: `Goken's trained language experts are here to provide quality solutions. Using Goken’s translation and interpretation services, you can understand key messages, make decisions and give directions on the spot, comprehend important information, effectively convey the message and ultimately impact your company’s financial KPI.`,
            // link: "",
            // linkText: "Read more...",
            image: engServices,
            mobileImage: mobConsult,
            mobilePara:
              "Goken's experience in engineering design spans the entire cycle right from - Initial Concept to Production Launch.",
          },
          // {
          //   heading: "ADAS VNV Service",
          //   paragraph: `Goken's experience in engineering design spans the entire cycle right from - Initial Concept to Production Launch. Our core expertise includes designing Body, Chassis, and Interior systems. Engineers at Goken have worked with part designs, sub-system designs, and system designs for numerous leading companies.`,
          //   // link: "",
          //   // linkText: "Read more...",
          //   image: adasImg,
          // mobileImage:""
          // },
          {
            heading: "Staffing",
            paragraph: `Our relentless focus on cultural fit, current skills, and future potential allows us to better streamline search parameters and thus, provide more reliable outcomes.We engage our clients with flexible hiring models - direct hire, on contract etc which promises a high performing team of quality engineering expertise. This can be a cost-effective solution for businesses that have fluctuating engineering needs.`,
            // link: "",
            // linkText: "Read more...",
            image: staffingImg,
            mobileImage: mobStaffing,
            mobilePara:
              "Our relentless focus on cultural fit, current skills, and future potential allows us to better streamline search parameters and thus, provide more reliable outcomes.",
          },
          {
            heading: "Japanese Language Service",
            paragraph: `Goken's trained language experts are here to provide quality solutions. Using Goken’s translation and interpretation services, you can understand key messages, make decisions and give directions on the spot, comprehend important information, effectively convey the message and ultimately impact your company’s financial KPI.`,
            // link: "",
            // linkText: "Read more...",
            image: JPLangServices,
            mobileImage: mobLanguage,
            mobilePara:
              "Comprehend important information, effectively convey the message and ultimately impact your company’s financial KPI.",
          },
        ],
      },
      ja: {
        heading: "GokenNIHON",
        jaColoredTitle: "のサービス",
        linkText: "お問い合わせフォーム",
        link: "/contactus/",
        list: [
          {
            heading: "エンジニアリング​<br/>サービス",
            paragraph: `エンジニアリングデザインにおけるGokenの実績は、初期コンセプトから生産開始までの製品開発サイクル全体に及びます。私たちの専門分野は、主に車体、シャーシ、内装・外装システムの設計です。Gokenのエンジニアは、大手OEMやサプライヤーの部品設計、サブシステム設計、システム設計に携わってきました。`,
            // link: "",
            // linkText: "Read more...",
            image: engDesign,
            mobileImage: mobEng,
            mobilePara: `Goken's experience in engineering design spans the entire cycle right from - Initial Concept to Production Launch.`,
          },

          {
            heading: "エンジニアリング <br> コンサルティング",
            paragraph: `Gokenの技術エキスパートは、設計、製造、検証、および製品開発のあらゆる面において約20年の実績を上げています。日本のOEMは、北米のサプライヤーと協力して部品開発を行っています。Gokenの技術エキスパートは、高品質な部品と納期厳守を保証するため、製品開発プロセスに関するコンサルティングの依頼をよく受けています。 `,
            // link: "",
            // linkText: "Read more...",
            image: engServices,
            mobileImage: mobConsult,
            mobilePara:
              "Goken's experience in engineering design spans the entire cycle right from - Initial Concept to Production Launch.",
          },
          // {
          //   heading: "ADAS VNV Service",
          //   paragraph: `Goken's experience in engineering design spans the entire cycle right from - Initial Concept to Production Launch. Our core expertise includes designing Body, Chassis, and Interior systems. Engineers at Goken have worked with part designs, sub-system designs, and system designs for numerous leading companies.`,
          //   // link: "",
          //   // linkText: "Read more...",
          //   image: adasImg,
          // mobileImage:""
          // },
          {
            heading: "人材派遣",
            paragraph: `候補者の企業文化への適合性、技術的スキル、将来の可能性に常に重点を置くことで、検索パラメータを合理化し、より信頼性の高い結果を提供することができます。当社は、直接雇用、契約社員、派遣社員など柔軟な雇用形態をお客様に提供し、質の高いエンジニアリングの専門知識を持つパフォーマンスの高いチームをお約束します。これは、エンジニアリングのニーズが変動する企業にとって、費用対効果の高いソリューションとなります。`,
            // link: "",
            // linkText: "Read more...",
            image: staffingImg,
            mobileImage: mobStaffing,
            mobilePara:
              "Our relentless focus on cultural fit, current skills, and future potential allows us to better streamline search parameters and thus, provide more reliable outcomes.",
          },
          {
            heading: "日英翻訳・通訳<br>サービス",
            paragraph: `Gokenのトレーニングを受けた翻訳・通訳のエキスパートが、質の高いソリューションを提供します。Gokenの翻訳・通訳サービスを利用すれば、重要なポイントを理解し、その場で意思決定や指示を行うことができます。重要な情報を理解し、重要なポイントを効果的に伝え、最終的には企業の財務KPIによい影響をもたらします。`,
            // link: "",
            // linkText: "Read more...",
            image: JPLangServices,
            mobileImage: mobLanguage,
            mobilePara:
              "Comprehend important information, effectively convey the message and ultimately impact your company’s financial KPI.",
          },
        ],
      },
    },
    jobs: {
      en: {
        heading: "Work With Us",
        image: workWithImg,
        list: [
          {
            title: "Design Engineer - Plastics",
            location: { title: "Location", value: "Yokohama, Japan" },
            jobType: { title: "", value: "Full Time" },
            link: "https://jobs.lever.co/gokenamerica1/bd782116-66fc-4faf-8be4-5b062c4c4d30",
            linkText: "Apply Now",
            experience: {
              title: "",
              value: `5-10 Years In automotive plastics design`,
            },
            // qualification:
            //   "B.E/ Diploma in Mechanical Engineering or equivalent",
            sections: [
              {
                title: "Responsibilities",
                list: [
                  "Design feasibility of Class A surface.",
                  "Create Concept 3D considering manufacturing requirements and readiness for mass production.",
                  "Internal Design & Engineering Reviews.",
                  "Create Manufacturing Drawings.",
                  "Manage regular client interactions.",
                  "Coordinate with offshore resource team.",
                ],
              },
              {
                title: "Job Requirement",
                list: [
                  "Must be Bilingual – Fluent in Japanese and English",
                  "Proficiency in CATIA V5/3DExperience is a must.",
                  "Proficient in Solid, Surface Modelling and Drafting.",
                  "Strong knowledge and experience in Automotive plastics parts design.",
                  "Engineering design and development experience in automotive plastic components.",
                  "Experience of OEM/Tier1 is preferred.",
                  "Knowledge of milestones in automotive product development.",
                  "Good understanding of manufacturing processes, DFMEA, GD&T & tolerance stack analysis.",
                  "Experience with MS Office tools like Excel and Powerpoint.",
                ],
              },
            ],
          },
        ],
      },

      ja: {
        heading: "一緒に働こう",
        image: workWithImg,
        list: [
          {
            title: "プログラムマネージャー – 自動車業界",
            location: { title: "勤務地", value: "横浜" },
            jobType: { title: "雇用形態", value: "正社員" },
            link: "https://jobs.lever.co/gokenamerica1/bd782116-66fc-4faf-8be4-5b062c4c4d30",
            linkText: " 今すぐ申し込む",
            experience: {
              title: "経験",
              value: `約10年の自動車樹脂部品設計の経験者 ホンダの樹脂部品開発経験者優遇`,
            },
            // location: "横浜",
            // jobType: "正社員",
            // experience: `約10年の自動車樹脂部品設計の経験者 ホンダの樹脂部品開発経験者優遇`,
            // qualification:
            //   "B.E/ Diploma in Mechanical Engineering or equivalent",
            sections: [
              {
                title: "仕事内容",
                list: [
                  `フィジビリティスタディ、3Dモデリングソフトウェアを使用した設計、レイアウトチェック、2D図面の作成を行い、大規模な製品開発プログラムのエンジニアリング業務のリードおよびサポート `,
                  "複雑な問題を独自に、または最小限の指導での分析および解決",
                  "部品仕様要件とBOMの作成",
                  "周辺部品担当者およびサプライヤーとの交渉",
                  "コスト分析、強度計算、および材料選択の実行 ",
                  "ベストプラクティスや社内外の業務課題に関する知識を活用し、製品やプロセスの改善",
                  `全体的な生産性を向上させ、最新技術を活用し、部品コストを削減できる新しい方法の模索`,
                  `客先への出張あり`,
                ],
              },
              {
                title: "資格・経験",
                list: [
                  `CATIA V5/3DEXPERIENCEのスキル必須`,
                  `ソリッド・サーフェスモデリングおよび製図のスキル`,
                  `自動車用樹脂部品のエンジニアリング設計および開発経験 `,
                  `日本語での高いコミュニケーション能力および日英バイリンガルスキルは必須`,
                  `OEM/ティア1の経験必須`,
                  `ホンダ部品開発経験者優遇`,
                  `自動車製品開発のマイルストーンに関する知識は必須`,
                  `製造工程、DFMEA、GD&T、累積公差分析に関する十分な理解`,
                  `ExcelやPowerPointなどのMS Officeツールの使用経験は必須`,
                ],
              },
            ],
          },
        ],
      },
    },
    contactUs: {
      en: {
        heading: "Let Us Assist You",
        paragraph1: `Have a question or want to know more about us?`,
        paragraph2: `Drop your email below and let us get back to you!`,
        emailPlaceHolder: "Enter Your Email",
        link: "/contactus/",
        linkText: "Lets Talk.",
      },
      ja: {
        heading: "質問はありますか？",
        paragraph1: `質問や当社について知りたいことはありますか？`,
        paragraph2: `下記にメールアドレスを入力してください。折り返し連絡します！`,
        emailPlaceHolder: "メールアドレスを入力",
        link: "/contactus/",
        linkText: "話そう",
      },
    },
    map: {
      en: {
        heading: "GokenNIHON Company Ltd.",
        mandatoryLink: "https://gokennihon.jp/about/haken.pdf",
        linkText: "【派遣事業の情報提供】",
        mapParagraph1: `〒223-0053`,
        mapParagraph2: `神奈川県横浜市港北区綱島西2-5-8 ニックハイム綱島 第8 101`,
        infoList: {
          leftInformation: [
            {
              title: `社名`,
              paragraph: `GokenNIHON 株式会社`,
            },
            {
              title: `所在地`,
              paragraph: `本社】〒223-0053 神奈川県横浜市港北区綱島西2‐5‐8 ニックハイム綱島 第8 101号`,
            },
            {
              title: `資本金`,
              paragraph: `1000万円`,
            },

            {
              title: `設立年月`,
              paragraph: `2006年9月1日`,
            },
          ],
          rightInformation: [
            {
              title: `取引先実績敬称略`,
              paragraph1: `本田技研工業 株式会社 / 株式会社 ホンダロック / 株式会社 ホンダテクノフォート / 住友重機械工業 株式会社 / 株式会社 ユタカ技研 / 株式会社 ユニプレス技術研究所 / テュフ・ラインランド・ジャパン 株式会社 / 河西工業株式会社 / 株式会社 エイチワン / 株式会社 丸順 / 株式会社 シバックス / 株式会社 フィアロコーポレーション / 東海興業株式会社 / テイ・エステック株式会社`,
              paragraph2: `HONDA R&D AMERICAS Inc. / HONDA ENGINEERING NORTH AMERICA Inc. / Minebea AccessSolutions Inc. / TS TECH NORTH AMERICA, INC. / BRIDGESTONE APM COMPANY / M-TEK, Inc. /COMMERCIAL VEHICLE GROUP, Inc.`,
            },
            {
              title: `業務実績`,
              paragraph1: `自動車の各種コンポーネントの設計・開発支援電気自動車の設計・開発支援日英・英日技術翻訳・通訳　日米技術コンサルティング`,
              paragraph2: `労働者派遣事業許可番号　派 14-301756`,
            },
            {
              title: "取引銀行",
              paragraph: "三井住友銀行、横浜信用金庫",
            },
          ],
        },
      },
      ja: {
        heading: "会社概要",
        secreteLink: "https://gokennihon.jp/about/haken.pdf",
        mapParagraph: `〒223-0053神奈川県横浜市港北区綱島西2-5-8 ニックハイム綱島 第8 101`,
        mandatoryLink: "https://gokennihon.jp/about/haken.pdf",
        linkText: "【派遣事業の情報提供】",
        infoList: {
          leftInformation: [
            {
              title: `社名`,
              paragraph: `GokenNIHON 株式会社`,
            },
            {
              title: `所在地`,
              paragraph: `本社】〒223-0053 神奈川県横浜市港北区綱島西2‐5‐8 ニックハイム綱島 第8 101号`,
            },
            {
              title: `資本金`,
              paragraph: `1000万円`,
            },

            {
              title: `設立年月`,
              paragraph: `2006年9月1日`,
            },
          ],
          rightInformation: [
            {
              title: `取引先実績敬称略`,
              paragraph1: `本田技研工業 株式会社 / ミネベアアクセスソリューションズ株式会社 / 株式会社 ホンダテクノフォート / 住友重機械工業 株式会社 / 株式会社 ユタカ技研 / 株式会社 ユニプレス技術研究所 / テュフ・ラインランド・ジャパン 株式会社 / 河西工業株式会社 / 株式会社 エイチワン / 株式会社 丸順 / 株式会社 シバックス / 株式会社 フィアロコーポレーション / 東海興業株式会社 / テイ・エステック株式会社`,
              paragraph2: `HONDA R&D AMERICAS Inc. / HONDA ENGINEERING NORTH AMERICA Inc. / Minebea AccessSolutions Inc. / TS TECH NORTH AMERICA, INC. / BRIDGESTONE APM COMPANY / M-TEK, Inc. /COMMERCIAL VEHICLE GROUP, Inc.`,
            },
            {
              title: `業務実績`,
              paragraph1: `自動車の各種コンポーネントの設計・開発支援電気自動車の設計・開発支援日英・英日技術翻訳・通訳　日米技術コンサルティング`,
              paragraph2: `労働者派遣事業許可番号　派 14-301756`,
            },
            {
              title: "取引銀行",
              paragraph: "三井住友銀行、横浜信用金庫",
            },
          ],
        },
      },
    },
    testimonials: {
      en: {
        heading: "Employee Testimonial",
        list: [
          {
            name: `エンジニア`,
            designation: `2014年4月入社`,
            paragraph1: `私は乗用車の駆動部品の開発業務に携わっています。
部品が精密な為、精密測定をして品質保証をしたり、部品の寸法が規格外になった原因究明や対策を考えたりしています。
測定評価のロジックや物の機能や組付けを理解するのが難しいですが、理解できてくると物の見方が変わっていろいろな考え方ができるのが楽しいです。`,
            paragraph2: ``,
          },
          {
            name: `エンジニア`,
            designation: `2016年4月入社`,
            paragraph1: `自動車等の解析業務を行っていました。現在は、製品の実験検証をメインで行っています。
現在の派遣先では、解析ソフトや様々な実験機器の使い方などを知ることが出来ました。
自分自身のスキルアップにつながり、自分の価値を高めることで、「成長したな」と実感してやりがいを感じます。
社員数は多くはありませんが、お互いの距離が近く、困ったことがあったら親身に相談に乗ってくれる会社だと思っています。`,
            paragraph2: ``,
          },
          {
            name: `翻訳・通訳者`,
            designation: `2018年10月入社`,
            paragraph1: `私が勤務している自動車メーカー様での業務は、翻訳業務が7割、通訳業務が3割を占めております。
北米の工場とのやりとりが多い為、時差の都合で会議は早朝や夜にもあります。
また、年に数回、通訳業務で国内の生産工場へ出張に同行させて頂いております。
通訳は、言わば「人と人の間に立つ言語サポート」です。その中で感謝の言葉をいただけることにやりがいを感じます。`,
            paragraph2: ``,
          },
          {
            name: `翻訳・通訳者`,
            designation: `2020年1月入社`,
            paragraph1: `翻訳という仕事に携わってみて思うことは、まず単純に難しいということです。
まだ新入りということもありますが、今まで見たことも聞いたこともないような専門用語に溢れています。
これまで翻訳・通訳業務にあたっていた方達が作ってくれた単語帳等をその都度調べたり、周りの方に聞いたりしながらなんとか翻訳している現状です。本当に毎日が勉強の日々です。`,
          },
        ],
      },
      ja: {
        heading: "従業員の声",
        list: [
          {
            name: `エンジニア`,
            designation: `2014年4月入社`,
            paragraph1: `私は乗用車の駆動部品の開発業務に携わっています。
部品が精密な為、精密測定をして品質保証をしたり、部品の寸法が規格外になった原因究明や対策を考えたりしています。
測定評価のロジックや物の機能や組付けを理解するのが難しいですが、理解できてくると物の見方が変わっていろいろな考え方ができるのが楽しいです。`,
            paragraph2: ``,
          },
          {
            name: `エンジニア`,
            designation: `2016年4月入社`,
            paragraph1: `自動車等の解析業務を行っていました。現在は、製品の実験検証をメインで行っています。
現在の派遣先では、解析ソフトや様々な実験機器の使い方などを知ることが出来ました。
自分自身のスキルアップにつながり、自分の価値を高めることで、「成長したな」と実感してやりがいを感じます。
社員数は多くはありませんが、お互いの距離が近く、困ったことがあったら親身に相談に乗ってくれる会社だと思っています。`,
            paragraph2: ``,
          },
          {
            name: `翻訳・通訳者`,
            designation: `2018年10月入社`,
            paragraph1: `私が勤務している自動車メーカー様での業務は、翻訳業務が7割、通訳業務が3割を占めております。
北米の工場とのやりとりが多い為、時差の都合で会議は早朝や夜にもあります。
また、年に数回、通訳業務で国内の生産工場へ出張に同行させて頂いております。
通訳は、言わば「人と人の間に立つ言語サポート」です。その中で感謝の言葉をいただけることにやりがいを感じます。`,
            paragraph2: ``,
          },
          {
            name: `翻訳・通訳者`,
            designation: `2020年1月入社`,
            paragraph1: `翻訳という仕事に携わってみて思うことは、まず単純に難しいということです。
まだ新入りということもありますが、今まで見たことも聞いたこともないような専門用語に溢れています。
これまで翻訳・通訳業務にあたっていた方達が作ってくれた単語帳等をその都度調べたり、周りの方に聞いたりしながらなんとか翻訳している現状です。本当に毎日が勉強の日々です。`,
          },
        ],
      },
    },
  };
  useEffect(() => {
    let langData = {};
    let dataSelector = "ja";
    let dataObjectKeys = Object.keys(data);
    if (!data[dataObjectKeys[0]][dataSelector]) {
      dataSelector = "en";
    }
    dataObjectKeys.map((pageSection) => {
      if (data[pageSection][dataSelector]) {
        console.log("Section for pagedata", pageSection);
        langData[pageSection] = data[pageSection][dataSelector]
          ? data[pageSection][dataSelector]
          : data[pageSection]["en"];
      }
    });

    console.log("page Section data", langData);

    setPageData(langData);
  }, [currentLang]);

  return (
    <Layout isNavWhite hideFAQ hideMap hideMenubar>
      <SEO
        title="Goken | GokenNIHON"
        canonicalLink={"https://www.goken-global.com/gokennihon/"}
        pageName={"Goken Nihon"}
      />
      <div className="goken-nihon-wrapper">
        {/* <Header
          heading={pageData?.header?.pageHeading}
          paragraph1={pageData?.header?.paragraph1}
          paragraph2={pageData?.header?.paragraph2}
          bigImage={headerBigImg}
          pageHeading={pageData?.header?.heading}
          subHeading={pageData?.header?.subHeading}
          para={pageData?.header?.para}
          smallImage={headerSmallImg}
        /> */}
        {/* <Header
          headingOne={pageData?.header?.headingOne}
          headingTwo={pageData?.header?.headingTwo}
          first={{ ...pageData?.header?.establish }}
          second={{ ...pageData?.header?.location }}
          bgImage={headerBigImg}
          mobileImage={headerSmallImg}
          secondHeadingClass="color-ja"
          hideImageGradient={true}
        /> */}
        <PageHeader
          headingOne={pageData?.header?.headingOne}
          headingTwo={pageData?.header?.headingTwo}
          first={{ ...pageData?.header?.establish }}
          second={{ ...pageData?.header?.location }}
          bgImage={headerBigImg}
          mobileImage={headerSmallImg}
          secondHeadingClass="color-ja"
          hideImageGradient={true}
        />
        {/* <Header bigImage={bi} /> */}
        <GNXDescription
          heading={pageData?.header?.pageHeading}
          paragraph1={pageData?.header?.paragraph1}
          paragraph2={pageData?.header?.paragraph2}
          bigImage={headerBigImg}
          pageHeading={pageData?.header?.heading}
          subHeading={pageData?.header?.subHeading}
          para={pageData?.header?.para}
          smallImage={headerSmallImg}
        />
        <FundamentalBeliefs
          // heading={pageData?.beliefs?.heading}
          paragraph={pageData?.beliefs?.paragraph}
          beliefsList={pageData?.beliefs?.list}
          redHeading1={pageData?.beliefs?.title1}
          redHeading2={pageData?.beliefs?.title2}
          redHeading3={pageData?.beliefs?.title3}
        />
        <GNXServices
          heading={pageData?.services?.heading}
          link={pageData?.services?.link}
          linkText={pageData?.services?.linkText}
          jaHeading={pageData?.services?.jaColoredTitle}
          servicesList={pageData?.services?.list}
        />
        <EmployeeTestimonial
          leftArrImg={leftJaArr}
          rightArrImg={rightJaArr}
          heading={pageData?.testimonials?.heading}
          testimonialList={pageData?.testimonials?.list}
        />
        {/* <EmployeeTestimonial
        heading={pageData?.testimonials?.heading}
        testimonialList={pageData?.testimonials?.list}
        leftArrImg={leftJaArr}
        rightArrImg={rightJaArr}
      /> */}

        <Jobs
          heading={pageData?.jobs?.heading}
          paragraph={pageData?.jobs?.paragraph}
          jobList={pageData?.jobs?.list}
          bannerImage={pageData?.jobs?.image}
        />

        <ContactUs
          heading={pageData?.contactUs?.heading}
          paragraph={pageData?.contactUs?.paragraph}
          link={pageData?.contactUs?.link}
          linkText={pageData?.contactUs?.linkText}
          placeholder={pageData?.contactUs?.emailPlaceHolder}
          paragraph1={pageData?.contactUs?.paragraph1}
          paragraph2={pageData?.contactUs?.paragraph2}
          comapnyHeading={pageData?.map?.heading}
        />
        {/* <Map
        heading={pageData?.map?.heading}
        paragraph1={pageData?.map?.mapParagraph1}
        paragraph2={pageData?.map?.mapParagraph2}
      /> */}
        <Footer
          heading={pageData?.map?.heading}
          list={pageData?.map?.infoList}
          mandatoryLink={pageData?.map?.mandatoryLink}
          linkText={pageData?.map?.linkText}
          paragraph1={pageData?.map?.mapParagraph1}
          paragraph2={pageData?.map?.mapParagraph2}
        />
      </div>
    </Layout>
  );
};

export default GokenNihon;
